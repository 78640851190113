.BannerSk {
    animation: skeleton 1s ease infinite alternate;
}
.SkBgColor {
    background-color: #dee3e4;
}
.SkBorderRadius {
    border-radius: 50rem !important;
}
@keyframes skeleton {
    to {
        opacity: 0.5;
    }
}
.BannerSkTitle, .BannerSkTyped {
    min-height: 120px;
}
.BannerSkLink {
    min-height: 35px;   
}
.BannerSkImg {
    width: 450px;
    height: 450px;
    margin: 0 auto;
}
.BannerSkImgSmall {
    width: 100px;
    height: 100px;
}
.BannerSkImgMed {
    width: 200px;
    height: 200px;
}
.BannerSkImgLrg {
    width: 300px;
    height: 300px;
}
.BannerSkExpt {
    min-height: 100px;   
}
.BannerSkPara {
    min-height: 200px;   
}